import { useEffect, useState } from "react";
import axios from "axios";
import { getEmailedUsers } from "../utils/util";
import UserTable from "./UserTable";
import React from "react";
import { fetchAllUserData } from "../api/api";

export default function EmailTable() {
  const [emailedUsers, setEmailedUsers] = useState<any>([]);
  useEffect(() => {
    const doWork = async () => {
      setEmailedUsers(getEmailedUsers(await fetchAllUserData()));
    };
    doWork();
  }, []);
  return (
    <div>
      <UserTable data={emailedUsers} />
    </div>
  );
}
