import React, { useEffect } from "react";

export default function LogoutPage() {
  useEffect(() => {
    localStorage.removeItem("key");
    window.location.href = "/login";
  }, []);
  return (
    <div>
      <h1>Logging out</h1>
    </div>
  );
}
