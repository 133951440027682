import { Route, Routes } from "react-router-dom";
import EmailTable from "./components/EmailTable";
import Home from "./components/Home";
import UserPage from "./components/UserPage";
import LoginPage from "./LoginPage";
import LogoutPage from "./LogoutPage";

/**
 * The main App component.
 * @returns None
 */
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/user/:id" element={<UserPage />} />
      <Route path="/emails" element={<EmailTable />} />
    </Routes>
  );
}

export default App;
