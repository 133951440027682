import React, { useState } from "react";

export default function LoginPage() {
  const [text, updateText] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await localStorage.setItem("key", text);
    window.location.href = "/";
  };

  return (
    <form onSubmit={(e: any) => handleSubmit(e)}>
      <input
        type="password"
        onChange={(e: any) => updateText(e.target.value)}
      />
      <input type="submit" />
    </form>
  );
}
