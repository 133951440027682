import axios from "axios";
import { useDebugValue, useState } from "react";
import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import LanguageGraph from "./LanguageGraph";
import {
  getLanguageDistribution,
  getPricePerUser,
  emojifyObject,
} from "../utils/util";
import { getEmail } from "../utils/emails";

export default function UserPage() {
  const { id }: any = useParams();
  const [languageData, setLanguageData] = useState<any>([]);
  const [price, setPrice] = useState<number>(0);
  const [userData, setUserData] = useState<any>([]);
  const [email, setEmail] = useState<any>(
    "Click button below for email body..."
  );
  const [subject, setSubject] = useState<any>(
    "Click button below for email subject..."
  );
  const [emailId, setEmailId] = useState<any>("");
  const [currentAdmin, setCurrentAdmin] = useState("Victor");
  useEffect(() => {
    let key = localStorage.getItem("key");
    if (!key) {
      window.location.href = "/login";
    }
    try {
      const getUserInfo = async () => {
        let data = await axios.post(
          "https://api.readable.so/stats/user/info/",
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Token ${key}`,
            },
          }
        );
        setUserData(emojifyObject(data.data));
        //DO IS AUTHENTICATED
      };
      const getUserComments = async () => {
        let data = await axios.post(
          "https://api.readable.so/stats/user/comments/",
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Token ${key}`,
            },
          }
        );
        console.log(data);
        setPrice(getPricePerUser(data.data));
        setLanguageData(getLanguageDistribution(data.data));

        //DO IS AUTHENTICATED
        console.log("Comments", languageData);
      };
      getUserInfo();
      getUserComments();
      console.log("USER", userData);
    } catch (error) {
      window.location.href = "/login";
      console.log(error);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };
  const handleChangeSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const handleClick = (email: string) => {
    setEmail(getEmail(userData.username, currentAdmin, email).body);
    setSubject(getEmail(userData.username, currentAdmin, email).subject);
    setEmailId(getEmail(userData.username, currentAdmin, email).id);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(email);
    let key = localStorage.getItem("key");
    if (!key) {
      window.location.href = "/login";
    }
    try {
      let data = await axios.post(
        "https://api.readable.so/stats/email/",
        // "http://127.0.0.1:8000/stats/email/",
        {
          id: emailId,
          recipient: userData.email,
          subject: subject,
          body: email,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
          },
        }
      );
      console.log(data);
      alert(`Email Sent to ${userData.username}! Message: ${data.data}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="user-container">
        <Link to="/">Back</Link>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h3 style={{ marginLeft: "20px" }}>Id: {userData.id} </h3>
          <h3 style={{ marginLeft: "20px" }}>Name: {userData.username} </h3>
          <h3 style={{ marginLeft: "20px" }}>Email: {userData.email} </h3>
          <h3 style={{ marginLeft: "20px" }}>Plan: {userData.plan} </h3>
          <h3 style={{ marginLeft: "20px" }}>
            Trial End: {userData.trial_end}
          </h3>
          <h3 style={{ marginLeft: "20px" }}>
            Estimated Price: {price.toFixed(2)}
          </h3>
          <h3 style={{ marginLeft: "20px" }}>
            Sent Emails: {userData.sent_emails}
          </h3>
        </div>
      </div>
      <LanguageGraph language_data={languageData} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          value={subject}
          style={{ width: "700px", height: "20px" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeSubject(e)
          }
          type="text"
        />
        <textarea
          // Default width and height
          style={{ width: "700px", height: "270px" }}
          id="email-form"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e)
          }
          value={email}
        ></textarea>
      </div>
      <button onClick={() => setCurrentAdmin("Victor")}>Victor</button>
      <button onClick={() => setCurrentAdmin("Nevin")}>Nevin</button>
      <br />
      <button onClick={() => handleClick("premium")}>Premium</button>
      <button onClick={() => handleClick("startTrial")}>
        Start Free Trial
      </button>
      <button onClick={() => handleClick("endTrial")}>End Free Trial</button>
      <button onClick={() => handleClick("noComments")}>No Comments</button>
      <button onClick={() => handleClick("convert")}>Convert</button>
      <button onClick={() => handleClick("custom")}>Custom</button>
      <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
        <input type="submit" />
      </form>
    </div>
  );
}
