import axios from "axios";
import {
  emojifyArray,
  getDailyActiveUsers,
  getLanguageDistribution,
} from "../utils/util";

/**
 * Gets the key from local storage and redirects to the login page if it is not found.
 * @returns None
 */
const getKey = () => {
  let key = localStorage.getItem("key");
  console.log("key " + key);
  if (!key) {
    window.location.href = "/login";
  }
  return key;
};

const handleError = () => {
  alert("Wrong Key");
  window.location.href = "/login";
};

export const fetchAllUserData = async () => {
  try {
    let data = await axios.post(
      "https://api.readable.so/stats/",
      {},
      {
        headers: {
          Authorization: `Token ${getKey()}`,
        },
      }
    );
    return emojifyArray(data.data);
  } catch (err: any) {
    handleError();
    console.log(err);
  }
};

export const fetchDailyActiveUserData = async () => {
  try {
    let data = await axios.post(
      "https://api.readable.so/stats/doa/",
      {},
      {
        headers: {
          Authorization: `Token ${getKey()}`,
        },
      }
    );
    // Get the length of array of individuals who commented today
    return getDailyActiveUsers(data.data).length;
  } catch (err: any) {
    handleError();
    console.log(err);
  }
};

export const fetchCommentData = async () => {
  try {
    let data = await axios.post(
      "https://api.readable.so/stats/comments/",
      {},
      {
        headers: {
          Authorization: `Token ${getKey()}`,
        },
      }
    );
    return getLanguageDistribution(data.data);
    //showing page
  } catch (err: any) {
    handleError();
    console.log(err);
  }
};
// const fetchNewUserData = async () => {
//   try {
//     let data = await axios.post(
//       "https://api.readable.so/stats/users/new/",
//       {},
//       {
//         headers: {
//           Authorization: `Token ${key}`,
//         },
//       }
//     );
//     console.log(data);
//     setNewUsers(data);
//   } catch (error) {
//     console.log(error);
//   }
// };

// await fetchNewUserData();
