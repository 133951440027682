import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function LanguageGraph({ language_data }: any) {
  return (
    <div style={{ height: "400px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={language_data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray={"3 3"} />
          <XAxis dataKey={"language"} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="docstring" fill="#8884d8" />
          <Bar dataKey="inline" fill="#15C2C2" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
