import React from "react";
import { TableState, usePagination, useSortBy, useTable } from "react-table";
import styled from "styled-components";

export default function UserTable({ data }: any) {
  // let router = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "Personal Info",
        columns: [
          {
            Header: "Id",
            accessor: "id",
          },
          {
            Header: "Username",
            accessor: "username",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Plan",
            accessor: "plan",
          },
        ],
      },
      {
        Header: "Readable Stats",
        columns: [
          {
            Header: "Docstring Comments",
            accessor: "docstring_comments",
          },
          {
            Header: "Inline Comments",
            accessor: "inline_comments",
          },
          {
            Header: "Trial End",
            accessor: "trial_end",
          },
          {
            Header: "Sent Emails",
            accessor: "sent_emails",
          },
        ],
      },
    ],
    []
  );

  const Styles = styled.div`
    padding: 1rem;

    table {
      border-spacing: 0;
      border: 1px solid black;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    pageCount,
    pageOptions,
    canNextPage,
    canPreviousPage,
    previousPage,
    nextPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as Partial<TableState<object>>,
    },
    useSortBy,
    usePagination
  );

  const handleClick = (id: any) => {
    console.log(id);
    if (id !== undefined) {
      window.open(`https://stats.readable.so/user/${id}`, "_blank");
    }
  };

  return (
    <>
      <Styles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(
              (headerGroup: {
                getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                  React.ClassAttributes<HTMLTableRowElement> &
                  React.HTMLAttributes<HTMLTableRowElement>;
                headers: any[];
              }) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              )
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row: any, i: any) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClickCapture={() => handleClick(page[i].original.id)}
                        key={Math.random()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 50, rows.length].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </Styles>
    </>
  );
}
