import { useEffect } from "react";
import { useState } from "react";
import "../App.css";
import Stats from "./Stats";
import {
  fetchAllUserData,
  fetchDailyActiveUserData,
  fetchCommentData,
} from "../api/api";

export default function Home() {
  const [doa, setDoa] = useState<any>(0);
  const [data, setData] = useState<any>([]);
  const [langaugeData, setLanguageData] = useState<any>([]);
  // for later
  // const [newUsers, setNewUsers] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const doWork = async () => {
      setData(await fetchAllUserData());
      setDoa(await fetchDailyActiveUserData());
      setLanguageData(await fetchCommentData());
      setLoading(false);
    };
    doWork();
  }, []);

  return (
    <div className="App">
      {!loading ? (
        <Stats data={data} count={doa} language_data={langaugeData} />
      ) : (
        <div>
          <h3>Loading...</h3>
        </div>
      )}
    </div>
  );
}
